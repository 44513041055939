<template>
  <div v-if="isPermission && isEditData">
    <div class="row" style="margin: 5px">
      <div class="col-md-12 col-12">
        <h2 class="font-weight-normal">
          {{ $t('createStockOut') }}
        </h2>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert id="createStockOutSuccessAlert" color="success" v-if="isSuccess === true">
            {{ $t('dialogSaveDocumentOutSuccess') }}
          </CAlert>
          <div class="row">
            <div class="col-md-12 col-12">
              <h4 class="font-weight-normal">
                {{ $t('information') }}
              </h4>
            </div>
          </div>
          <br />
          <div class="form-group row">
            <label class="col-12 col-md-2 col-form-label text-sm-right text-left">
              {{ $t('dateOfBill') }}
            </label>
            <div class="col-12 col-md-4">
              <VueDatePicker id="createStockOutDatePicker" color="#29B46B" :locale="getDateLocale()"
                  :visible-years-number="90" v-model="datetime" format="DD-MM-YYYY" class="form-control text-dark" >
                  <template v-slot="{ inputValue, inputEvents }">
                  <input id="createStockOutDateInput" :value="inputValue"
                    v-on="inputEvents" />
                </template></VueDatePicker>
            </div>
            <label class="col-12 col-md-2 col-form-label text-sm-right text-left">
              {{ $t('billNo') }}
            </label>
            <div class="col-12 col-md-4">
              <input
                id="createStockOutBillNoInput"
                class="form-control text-dark"
                v-model="billNo"
                :placeholder="$t('billNo')"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-md-2 col-form-label text-sm-right text-left">
              {{ $t('shop') }}
            </label>
            <div class="col-12 col-md-4">
              <select
                id="createStockOutShopSelect"
                class="custom-select text-dark"
                v-model="shopObjectId"
                disabled
              >
                <option
                  v-for="shop in shops"
                  :key="shop.objectId"
                  :value="shop.objectId"
                >
                  {{ shop.shopName }} - {{ shop.branchName }}
                </option>
              </select>
              <div id="createStockOutShopValidation" v-if="validateShop" class="text-danger">
                {{ validateShop }}
              </div>
            </div>
            <label class="col-12 col-md-2 col-form-label text-sm-right text-left">
              {{ $t('customer') }}
            </label>
            <div class="col-12 col-md-4">
              <multiselect
                id="createStockOutCustomerSelect"
                v-model="member"
                :options="memberslist"
                label="name"
                track-by="name"
                :placeholder="$t('customer')"
                :select-label="$t('pressEnterSelect')" :selected-label="$t('selected')"
                :deselect-label="$t('pressEnterRemove')"></multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-md-2 col-form-label text-sm-right text-left">
              {{ $t('reason') }}
            </label>
            <div class="col-12 col-md-10">
              <textarea
                id="createStockOutReasonTextarea"
                v-model="note"
                class="form-control text-dark"
                :placeholder="$t('reason')"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-5">
              <h4 class="font-weight-normal">
                {{ $t('stockOutList') }}
              </h4>
              <p>
                {{ $t('addstockOutList') }}
              </p>
            </div>
            <div class="col-md-4 col-8 text-right">
              <CButton id="createStockOutImportDocButton" class="btn btn-outline-success btn-block" style="display: flex; align-items: center; justify-content: center;" @click="AddCheckStockModal = true">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i> {{ $t('importcheckStockDoc') }}
              </CButton>
            </div>
            <div class="col-md-3 col-4 text-right">
              <CButton
                id="createStockOutAddProductButton"
                class="btn btn-outline-success btn-block"
                @click="AddProductModal = true"
                style="display: flex; align-items: center; justify-content: center;"
              >
              <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>{{ $t('addProduct') }}
              </CButton>
            </div>
          </div>

          <CRow class="mt-3">
            <CCol sm="12" lg="12">
              <table
                id="createStockOutProductTable"
                style="white-space: nowrap;"
                v-if="items.length !== 0"
                class="table table-sm table-responsive table-bordered text-dark font-weight-normal">
                <thead>
                  <tr>
                    <th class="text-dark text-center font-weight-normal">#</th>
                    <th ></th>
                    <th class="text-dark font-weight-normal">{{ $t('pluCode') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('productName') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('unit') }}</th>
                    <th class="text-dark text-right font-weight-normal">{{ $t('quantity') }}</th>
                    <th class="text-dark text-right font-weight-normal">{{ $t('stockUnit') }}</th>
                    <th class="text-dark text-right font-weight-normal">{{ $t('discount') }}</th>
                    <th class="text-dark text-right font-weight-normal">{{ $t('total') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td :id="'itemIndex'+ index" style="width: 2%;" class="text-center" :value="(item.orderIndex = index)">
                      <div style="margin-top:6px;">
                        {{ index + 1 }}
                      </div>
                    </td>
                    <td style="width:5%">
                      <div v-if="item.remoteImagePath"
                        :style="{
                          'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                          'background-size': 'cover',
                          'background-position': 'center center',
                          'width': 'auto',
                          'border-radius': '2px',
                          'aspect-ratio': '1.3',
                          'min-width': '50px',
                        }"
                      ></div>
                      <div
                        v-else
                        style="border-radius: 2px"
                        :style="{
                          'aspect-ratio': '4 / 3',
                          'min-width': '50px',
                          backgroundColor: item.indexColor,
                        }"
                      ></div>
                    </td>
                    <td>
                      <div style="margin-top:6px;">
                        {{ item.productPLU.PLUCode }}
                      </div>
                    </td>
                    <td style="width:30%">
                      <div class="text-dark font-weight-normal" style="margin-top: 6px">
                      <span v-if="item.vatType === 'N'">{{ item.productPLU.name || '' }}  <CBadge color="danger">Non VAT</CBadge></span>
                      <span v-else> {{ item.productPLU.name || '' }} </span>
                    </div>
                    </td>
                    <td>
                      <div style="margin-top:6px;">
                        {{ item.productPLU.unit ? (item.productPLU.unit.name || '-') : '-'  }}/{{
                        item.productPLU.SKURatio || '1'
                      }}
                      </div>
                    </td>
                    <td>
                      <input
                        style="width:100%; height:100%;"
                        type="number"
                        min="0"
                        v-model.number="item.quantity"
                        class="form-control form-control-md text-right text-dark"
                      />
                      <p
                        class="text-danger"
                        v-if="
                          Number(item.quantity < 0) ||
                            Number.isNaN(parseInt(item.quantity))
                        "
                      >
                        {{ $t('dialogfound') }}
                      </p>
                    </td>
                    <td>
                      <input
                        style="width:100%; height:100%;"
                        type="number"
                        class="form-control form-control-md text-right text-dark"
                        v-model.number="item.cost"
                      />
                    </td>
                    <td>
                      <input
                        style="width:100%; height:100%;"
                        class="form-control form-control-md text-right text-danger"
                        v-model.number="item.discount"
                      />
                    </td>
                    <td>
                      <input
                        style="width:100%; height:100%;"
                        class="form-control form-control-md text-right text-dark"
                        :value="grandTotalItem(item)"
                        disabled
                      />
                    </td>
                    <td class="text-center" style="width:2%">
                      <CButton
                        :id="'createStockOutDelteItemButton'+ index"
                        color="danger"
                        size="sm"
                        @click="removeProduct(item)"
                      >
                        <CIcon name="cil-trash"></CIcon>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <CDataTable v-else>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
              <div v-if="validateItem" class="text-danger" id="createStockOutValidationMessage">
            {{ validateItem }}
          </div>
              <p id="createStockOutWarningMessage" class="text-danger" v-if="warning != ''">{{ warning }}</p>
              <p id="createStockOutDuplicateItemMessage" v-if="duplicateItem != ''" class="text-danger">
                {{ duplicateItem }}
              </p>
              
            </CCol>
          </CRow>
          

          <br v-if="items.length != 0" />
          <div class="row justify-content-end" v-if="items.length != 0">
            <div id="createStockOutVATSwitchLabel" class="col-md-3 col-6 text-right">
              <p v-if="isVatIncluded === false">{{ $t('isVatIncluded') }}</p>
              <p v-else-if="isVatIncluded === true">
                {{ $t('vatIccludes') }}
              </p>
              <CSwitch
                id="createStockOutVATSwitch"
                v-model="isVatIncluded"
                color="success"
                :value="isVatIncluded"
                :checked.sync="isVatIncluded"
              />
            </div>
            <div id="createStockOutNetTotal" class="col-md-2 col-6 text-right">
              <p>{{ $t('totalNet') }}</p>
              <h4>{{ netTotalCurrencyString }}</h4>
            </div>
            <div id="createStockOutDiscountTotal" class="col-md-2 col-6 text-right">
              <p>{{ $t('discount') }}</p>
              <h4 class="text-right  " style="padding-left: 30px;">
                <input
                  id="createStockOutDiscountInput"
                  style="margin-top: -5px;"
                  v-model="discount"
                  class="form-control form-control-md mx-auto text-right text-danger "
                />
              </h4>
            </div>
            <div id="createStockOutVATTotal" class="col-md-2 col-6 text-right">
              <p>{{ $t('vat') }} 7%</p>
              <h4>{{ vatCurrencyString }}</h4>
            </div>
            <div id="createStockOutGrandTotal" class="col-md-2 col-6 text-right">
              <p>{{ $t('grandTotal') }}</p>
              <h4>{{ grandTotal }}</h4>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol>
      <div class="row justify-content-end" style="margin: 0">
        <CButton
          id="createStockOutSaveButton"
          class="btn btn-success  col-lg-2 col-4"
          style="margin-right: 15px;"
          @click="handlePopup"
          v-if="items.length != 0"
        >
          {{ $t('save') }}
        </CButton>
        <CButton class="btn btn-success col-lg-2 col-4" style="margin-right: 15px;" v-else disabled>
          {{ $t('save') }}
        </CButton>
        <CButton
          id="createStockOutSaveButtonDisabled"
          class="btn col-lg-2 col-4 shadow-sm font-weight-normal"
          style="background-color: white;"
          @click="$router.go(-1)"
        >
          {{ $t('cancel') }}
        </CButton>
      </div>
      <br />
    </CCol>
    <CRow>
      <CModal
        id="createStockOutModal"
        :show.sync="popupModal"
        color="success font-weight-normal"
        :title="$t('confirmSaveDoc')"
        centered
        :footer="footer"
      >
        <br />
        <h4 class="text-center font-weight-normal">{{ $t('confirmSaveDocStockOutModal') }}</h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton
                id="createStockOutSaveButton"
                block
                color="success"
                v-if="loadingButton === true"
                v-on:click="formSubmit"
              >
                {{ $t('save') }}
              </CButton>
              <CButton
                id="createStockOutSaveButtonDisabled"
                block
                color="success"
                v-else-if="loadingButton === false"
                disabled
              >
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol col="2"></CCol>
            <CCol col="4">
              <CButton id="createStockOutCancelButton" block color="light" @click="popupModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal
        id="createStockOutAddProductModal"
        :show.sync="AddProductModal"
        centered
        style="max-width: 915px;"
        :footer="footer"
        size="lg"
      >
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectdProduct') }}
            </h4>
            <button
              id="createStockOutModalCloseButton"
              type="button"
              aria-label="Close"
              class="close"
              @click="AddProductModal = false"
            >
              ×
            </button>
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <CRow>
              <label class="col-sm-1 col-form-label text-left text-dark font-weight-normal">
                {{ $t('search') }}
              </label>
              <CCol style="padding-right: 0;">
                <select id="createStockOutSearchBySelect" v-model="searchBy" class="custom-select no-border-radius-left">
                  <option value="PLUCode">{{ $t('pluCode') }}</option>
                  <option value="name">{{ $t('productName') }}</option>
                  <option value="category">{{ $t('category') }}</option>
                </select>
              </CCol>
              <CCol class="pl-0 col-6">
                <template v-if="searchBy === 'category'">
                  <select id="createStockOutCategorySelect" v-model="selectedCategory" class="form-control text-dark no-border-radius-right" :placeholder="$t('selectCategory')">
                    <option selected value="">{{ $t('selectCategory') }}</option>
                    <option v-for="category in dataCategory" :key="category.objectId" :value="category.objectId">
                      {{ category.name }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <input id="createStockOutKeywordInput" class="form-control text-dark no-border-radius-right" v-model="keywordEntered" @input="searchProductPLU"
                    :placeholder="$t('searchProduct')" />
                </template>
              </CCol>

              <CCol class="p-0">
                <CButton
                  id="createStockOutSearchButton"
                  block
                  color="success"
                  v-if="loadingButton === true"
                  v-on:click="searchProductPLU"
                >
                  {{ $t('search') }}
                </CButton>
              </CCol>
              <CCol>
                <CButton
                  id="createStockOutResetButton"
                  class="btn btn-outline-secondary text-dark font-weight-normal"
                  style="font-weight: bold; color: black; width: inherit"
                  v-on:click="reset"
                >
                  {{ $t('reset') }}
                </CButton>
              </CCol>
            </CRow>
            <div id="createStockOutProductList" style="max-height: 400px; overflow-y: auto;" class="mt-4">
              <CDataTable
                id="createStockOutDataTable"
                :fields="fields"
                :items="autoCompleteResult"
                hover
                v-model="autoCompleteResult"
                border
                clickableRows
                @row-clicked="onSelectedAutoCompleteEvent"
                pagination
              >
                <template slot="selected-header">
                  <div
                    id="createStockOutSelectedHeader"
                    class="custom-control custom-checkbox"
                    style="padding-left: 2rem"
                  >
                    <input
                      v-model="all"
                      type="checkbox"
                      @change="setAll()"
                      class="custom-control-input success cursor"
                      id="yyy"
                    />
                    <label class="custom-control-label cursor" for="yyy">
                    </label>
                  </div>
                </template>
                <template #selected="{ item, index }">
                  <td :id="'createStockOutRowSelected'+ index" style="vertical-align: middle;">
                    <div
                      class="custom-control custom-checkbox"
                      style="padding-left: 2rem"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input success cursor"
                        v-model="item.selected"
                        @change="onSelectedAutoCompleteEvent(item)"
                        :id="index"
                      />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item, index }">
                  <td
                    :id="'createStockOutItemName'+ index"
                    style="vertical-align: middle;border-left: hidden;"
                    :for="index"
                  >
                    <img
                      :id="'createStockOutItemImage'+ index"
                      v-if="item.remoteImagePath"
                      :src="item.remoteImagePath"
                      style="border-radius: 2px;aspect-ratio: 4 / 3;"
                      class="img-fluid"
                      @error="noImgUrl"
                    />
                    <div
                      v-else
                      style="border-radius: 2px"
                      :style="{
                        'aspect-ratio': '4 / 3',
                        backgroundColor: item.indexColor,
                      }"
                    ></div>
                  </td>
                </template>
                <template #nameproduct="{item, index}">
                  <td :id="'createStockOutProductName' + index" class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.name }}
                  </td>
                </template>
                <template #categoryName="{ item, index }">
                  <td :id="'createStockOutCategoryName' + index" class="text-dark font-weight-normal" style="vertical-align: middle;border-left: hidden;">
                    {{ item.categoryName }}
                  </td>
                </template>
                <template #pluCode="{item, index}">
                  <td :id="'createStockOutPLUCode' + index" class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.PLUCode }}
                  </td>
                </template>
                <template #price="{ item, index }">
                  <td
                    :id="'createStockOutItemPrice' + index"
                    :for="index"
                    style="vertical-align: middle;border-left: hidden "
                    v-if="
                      item.ProductSKU.stdCost === '' ||
                        item.ProductSKU.stdCost === undefined
                    "
                  >
                    ฿ 0
                  </td>
                  <td
                    :id="'createStockOutItemPrice' + index"
                    :for="index"
                    style="vertical-align: middle;border-left: hidden "
                    v-else
                  >
                    ฿ {{ item.ProductSKU.stdCost }}
                  </td>
                </template>
                <template #onhanQTY="{ item, index }">
                  <td
                    :id="'createStockOutOnhandQty' + index"
                    class="text-right"
                    :for="index"
                    style="vertical-align: middle; border-left: hidden"
                    v-if="
                    item.ProductPLUStock.onhandQty === '' ||
                    item.ProductPLUStock.onhandQty === undefined
                  "
                >
                  N/A
                </td>
                <td
                  :id="'createStockOutOnhandQty' + index"
                  class="text-right"
                  :for="index"
                  style="vertical-align: middle; border-left: hidden"
                  v-else
                >
                  <div
                    v-if="item.ProductPLUStock.onhandQty < 0 && Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                    class="text-danger"
                  >
                    {{ parseFloat(item.ProductPLUStock.onhandQty) }}
                  </div>
                  <div
                    v-else-if="item.ProductPLUStock.onhandQty < 0 && !Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                    class="text-danger"
                  >
                    {{ parseFloat(item.ProductPLUStock.onhandQty).toFixed(3) }}
                  </div>
                  <div
                    v-else-if="!Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                    class="text-dark"
                  >
                    {{ parseFloat(item.ProductPLUStock.onhandQty).toFixed(3) }}
                  </div>
                  <div v-else class="text-dark">
                    {{ parseFloat(item.ProductPLUStock.onhandQty) }}
                  </div>
                </td>
              </template>
                <template #no-items-view>
                  <div :id="'createStockOutNoItems'" class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton
                  id="createStockOutConfirmButton"
                  block
                  color="success"
                  v-if="loadingButton === true"
                  v-on:click="onCheckboxChange"
                >
                  {{ $t('confirm') }}
                </CButton>
                <CButton
                  id="createStockOutSaveDisabledButton"
                  block
                  color="success"
                  v-else-if="loadingButton === false"
                  disabled
                >
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton
                  id="createStockOutCancelButton"
                  class="btn btn-outline-secondary text-dark font-weight-normal"
                  style="font-weight: bold; width: inherit"
                  @click="AddProductModal = false"
                >
                  {{ $t('cancel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal :id="'createStockOutAddCheckStockModal'" :show.sync="AddCheckStockModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectCheckStockDoc') }}
            </h4>
            <CButton :id="'createStockOutCreateCheckStockButton'" to="/inventory/check-stock/create" color="warning">
              <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('createCheckStock') }}
            </CButton>
            <!-- <button type="button" aria-label="Close" class="close" @click="AddCheckStockModal = false">
              ×
            </button> -->
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <div style="max-height: 400px; overflow-y: auto;">
              <CDataTable :id="'createStockOutCheckStockDataTable'" :fields="checkStockFields" :items="checkStockItem" hover border v-model="checkStockItem"
                clickableRows @row-clicked="onSelectCheckStockDoc">

                <!-- <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="checkbox" />
                    <label class="custom-control-label cursor" for="checkbox">
                    </label>
                  </div>
                </template> -->
                <template slot="nameproduct-header">
                  <p></p>
                </template>
                <template #selected="{ item, index }">
                  <td :id="'createStockOutRowSelected' + index" style="vertical-align: middle; text-align: center;">
                    <div class="custom-control custom-checkbox " style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectCheckStockDoc(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item, index }">
                  <td :id="'createStockOutItemName' + index" class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.note }}
                  </td>
                </template>
                <template #no-items-view>
                  <div :id="'createStockOutNoItemsView'" class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="'createStockOutConfirmButton'" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton id="'createStockOutSaveButton'" block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="'createStockOutCancelButton'" class="btn btn-outline-secondary text-dark font-weight-normal" style="width: inherit"
                  @click="AddCheckStockModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/local'
import util from '@/util/util'
import Multiselect from 'vue-multiselect'
import permis from '@/util/permission'
import pos from '@/services/local'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      isSuccess: false,
      alert: false,
      data: [],
      datetime: new Date(),
      shop: this.$store.getters.shopObjectId,
      member: null,
      members: {},
      isVatIncluded: false,
      billNo: '',
      note: '',
      discount: '0',
      searchBy: 'name',
      label: 'name',
      keyword: '',
      itemNetTotal: '0.00',
      placeHolderInputText: 'พิมพ์ชื่อสินค้าที่ต้องการค้นหา .​.​.',
      autoCompleteResult: [],
      autoCompleteProgress: false,
      autoCompleteText: 'name',
      autoCompleteText2: 'PLUCode',
      autoCompleteText3: 'unitName',
      autoCompleteFieldId: 'alpha3Code',
      items: [],
      vatType: '',
      // itemList: [],
      validateShop: '',
      validateItem: '',
      popupModal: false,
      footer: '',
      loadingButton: true,
      duplicateItem: '',
      warning: '',
      AddProductModal: false,
      AddCheckStockModal: false,
      keywordEntered: '',
      selectedItems: [],
      all: false,
      checkStockItem: [],
      dataCategory: [],
      selectedCategory: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      let path = '/inventory/stock-out'
      return permis.findPermissionRead('inventory', path)
    },
    isEditData() {
      let path = '/inventory/stock-out'
      return permis.findPermissionEdit('inventory', path)
    },

    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    vat() {
      let vat = 0;
      this.items.forEach(item => {
        vat += this.calculateVat(item);
      });

      return vat;
    },
    vatCurrencyString() {
      return util.convertCurrency(this.vat);
    },
    grandTotal() {
      let grandTotal = ''
      let summary = this.netTotal || 0
      let vat = this.vat || 0

      if (this.isVatIncluded === false) {
        grandTotal = summary - this.discount
      } else if (this.isVatIncluded === true) {
        grandTotal = summary + vat - this.discount
      }

      return util.convertCurrency(grandTotal)
    },
    netTotal() {
      let total = 0;
      this.items.forEach(item => {
        total += this.grandTotalItem(item);
      });
      return total;
    },
    netTotalCurrencyString() {
      return util.convertCurrency(this.netTotal)
    },

    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    fields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:5%; vertical-align: middle;',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'name',
          label: '',
          _style: 'width:7%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'nameproduct',
          label: this.$i18n.t('product'),
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'pluCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:23%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'categoryName',
          label: this.$i18n.t('category'),
          _style: 'width:22%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'onhanQTY',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal  text-dark',
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
        },
      ]
    },
    memberslist() {
      const member = this.members || []
      let detail = []
      for (let i = 0; i < member.length; i++) {
        const item = member[i] 
        const fullName = (item.firstname || item.name || '') + ' ' + (item.lastname || '')
        detail.push({
          id: item.id,
          objectId: item.objectId,
          name: fullName,
        })
      }
      return detail
    },
    checkStockFields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:10%; vertical-align: middle;',
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'name',
          label: this.$i18n.t('checkStockName'),
          _style: 'width:90%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
      ]
    },
  },
  created() {
    this.getMember()
    this.searchProductPLU()
    this.getCheckStock()
    this.getCategory()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  methods: {
    ...util,
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        page: 1,
        limit: 1000,
      };
      const headers = {
          shopObjectId: shopObjectId,
      }

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
          params,headers
        })
        .then((res) => {
          let detail = []
          let data = res.data.data
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              detail.push({
                id: data[i].id,
                name: data[i].name,
                objectId: data[i].objectId,
              })
            }
          }
          this.dataCategory = detail
          })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    getCheckStock(page = 1) {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      // const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      // const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        page: page,
        limit: 50,

      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      axios({
        url: '/api/v2.2/' + uid + '/CheckStockDocument',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          this.data = this.data.filter(item => item.shop.objectId === this.shopObjectId);
          
          this.loadingButton = true
          
          let checkStockDoc = this.data.map(item => {
            return { ...item, selected: false };
          });
          this.checkStockItem = checkStockDoc
          
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onSelectCheckStockDoc(item) {
      
      item.selected = !item.selected
      let selectedDoc = [];

      if (item.items) {
        item.items.forEach(docItem => {
          let newItem = {
            PLUCode: docItem.productPLU?.PLUCode || '-',
            name: docItem.productPLU?.name || '-',
            ProductPLU: docItem.productPLU || {},
            ProductSKU: docItem.productSKU || {},
            id: docItem.productPLUId || '',
            objectId: docItem.productPLU?.objectId || '',
            unit: docItem.productPLU?.unit || {},
            unitName: docItem.productPLU?.unit?.name !== undefined 
                ? docItem.productPLU.unit.name 
                : (docItem.unit?.name !== undefined 
                    ? docItem.unit.name 
                    : '-'),
            ProductPLUStock: docItem.ProductPLUStock || {},
            SKURatio: docItem.productPLU?.SKURatio || 1,
            checkCount: docItem.quantity || 0,
            shop: docItem.shop || {},
            uid: this.uid || '',
            vatType: docItem.productSKU?.vatType || 'V'
          };
          const matchedIndex = this.autoCompleteResult.findIndex(
            (autoItem) =>
              autoItem.objectId === docItem.productPLU?.objectId
          );

          if (matchedIndex !== -1) {
            const matchedItem = this.autoCompleteResult[matchedIndex];
            newItem.ProductSKU.stdCost = matchedItem.stdCost || matchedItem.ProductSKU?.stdCost || 0;
          } else {
            newItem.ProductSKU.stdCost = 0;
          }
          if (item.selected) {
            newItem.selected = true;
          }

          selectedDoc.push(newItem);
        });
      }

      this.selectedItems.push(...selectedDoc);
      

    },
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: 'de' }
      }
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    handlePopup() {
      if (this.shopObjectId === '') {
        this.popupModal = false
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (this.items.length === 0) {
        this.popupModal = false
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      if (this.items.length != 0 && this.shopObjectId != '') {
        this.popupModal = true
      }
    },
    totalQuantity(item) {
      let totalQty = 0;

      for (const item of this.items) {
        totalQty += item.quantity;
      }
      return totalQty;
    },
    calculateDiscount(item) {
      const totalQty = this.totalQuantity();
      let discountPerQty = 0;
      if (totalQty !== 0) {
        discountPerQty = this.discount / totalQty;
      } else {
        discountPerQty = 0;
      }

      return discountPerQty
    },
    grandTotalItem(item) {
      let total = 0
      if (item.vatType === 'N') {
        total = item.quantity * item.cost - item.discount
      }
      if (item.vatType === 'V') {
        total = item.quantity * item.cost - item.discount
      } else {
        total = (item.quantity * item.cost) - item.discount;
      }
      return item.total = total
    },
    grandTotalItemDisperQty(item) {
      let totalnonvat = 0;
      let totalincvat = 0;
      let discountPerQty = this.calculateDiscount();
      if (item && item.vatType === 'N') {
        totalnonvat = item.quantity * item.cost - item.discount - (item.quantity * discountPerQty);
        item.total = totalnonvat;
        return totalnonvat
      } else if (item && item.vatType === 'V') {
        totalincvat = item.quantity * item.cost - item.discount - (item.quantity * discountPerQty);
        item.total = totalincvat;
        return totalincvat
      }
      const total = totalnonvat + totalincvat;
    },

    calculateVat(item) {
      let vat = 0;

      if (item.vatType === 'V' && this.isVatIncluded === false) {
        vat = (this.grandTotalItemDisperQty(item) * 7) / 107;
      }
      else if (item.vatType === 'V' && this.isVatIncluded === true) {
        vat = this.grandTotalItemDisperQty(item) * (7 / 100);
      }
      return vat;
    },
    removeProduct(i) {
      let items = this.items
      let newItems = items.filter((item) => item.orderIndex != i.orderIndex)
      this.items = newItems
      let index = this.autoCompleteResult.findIndex(
        (x) => x.objectId == i.productPLU.objectId
      )
      if (index != -1) {
        this.autoCompleteResult[index].selected = false
      }

      let find = this.selectedItems.findIndex(
        (x) => x.objectId === i.productPLU.objectId
      )
      if (find != -1) {
        this.selectedItems.splice(i, 1)
      }
    },
    getMember() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      const params = {
        shopObjectId: shopObjectId,
      }

      axios
        .get('/api/v1.0/' + uid + '/getmember', { params })
        .then((res) => {
          this.members = res.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
    
    onSelectedAutoCompleteEvent(item) {
      item.selected = !item.selected
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        let i = this.selectedItems.findIndex(
          (x) => x.objectId === item.objectId
        )
        if (i != -1) {
          this.selectedItems.splice(i, 1)
        }
      }
      let countItem = this.autoCompleteResult.length
      let coutselect = 0
      this.autoCompleteResult.forEach(function(item) {
        if (item.selected) {
          coutselect = coutselect + 1
        }
      })
      if (countItem === coutselect) {
        this.all = true
      } else {
        this.all = false
      }
    },
    searchProductPLU() {
      if (this.shopObjectId === '') {
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {}
      if (this.searchBy === 'category' && this.selectedCategory) {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          categoryObjectId: this.selectedCategory,
        }
      } else if (this.keywordEntered != '') {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
          keyword: this.keywordEntered,
        }
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
        }
      }
      this.label = this.searchBy
      let selectedItems = this.selectedItems
      // if (keywordEntered.length > 0) {
      const headers = {
        shopObjectId: this.$store.getters.shopObjectId,
      }
      axios({
        url: '/api/v1.0/' + uid + '/getproductpluwithstock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((response) => {
          this.alert = false
          let newData = []
          response.data.data.forEach(function(item) {
            if (selectedItems.length > 0) {
              let i = selectedItems.findIndex(
                (x) => x.objectId === item.objectId
              )
              if (i != -1) {
                item.selected = true
              } else {
                item.selected = false
              }
            } else {
              item.selected = false
            }
            const unit = item.unit || {}
            const category = item.category || {}
            item.unitName = unit.name || '-'
            item.categoryName = category.name || '-'
            item.indexColor = util.generateColor(item.ProductSKU.indexColor)
            newData.push(item)
          })
          this.autoCompleteResult = newData
        })
        .catch((e) => {
          this.alert = true
          console.log(e)
        })
      // }
    },
    formSubmit(e) {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      this.loadingButton = false
      e.preventDefault()
      let customerId = ''
      let customer = {}
      const plan = this.users.currentPlan

      this.items.forEach(function(item) {
        delete item.remoteImagePath
        delete item.indexColor
      })
      const data = {
        billNo: this.billNo,
        date: new Date(this.datetime).toISOString(),
        discount: util.currencyStringToNumber(this.discount),
        grandTotal: util.currencyStringToNumber(this.grandTotal),
        isVatIncluded: !this.isVatIncluded,
        netTotal: this.netTotal,
        note: this.note,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        stockDocType: 2,
        vatBase: 7,
        uid: this.uid,
        vat: this.vat,
        items: this.items,
        vatType: this.vatType,

      }
      if (this.member != null) {
        data['customerId'] = this.member.id || ''
        data['customer'] = {
          id: this.member.id || '',
          objectId: this.member.objectId || '',
          name: this.member.name || '',
        }
      }

      const headers = {
        shopObjectId: this.shopObjectId,
      }
      if (data.shopId != undefined && data.items.length != 0) {
        axios({
          method: 'post',
          url: '/api/v1.0/' + data.uid + '/stockdocument/save',
          params: { shopObjectId: data.shopObjectId, plan: plan },
          data: data,
          headers: headers,
        })
          .then(() => {
            //   setTimeout(() => {
            this.isSuccess = true
            // ,
            this.$router.push('/inventory/stock-out')
            // }, 5000)
          })
          .catch(function(error) {
            console.log(error)
          })
      }
    },
    reset() {
      this.selectedItems = []
      this.all = false
      this.keywordEntered = ''
      this.searchBy = 'name'
      this.selectedCategory = ''
      this.searchProductPLU()
    },
    onCheckboxChange() {
      let data = this.selectedItems
      let items = this.items
      let select = []
      data.forEach(function(item, index) {
        let stdCost = 0
        let SKURatio = 1
        let totalCost = 0
        if (item.selected) {
          select.push(item)
          if (
            item.ProductSKU.stdCost === undefined ||
            item.ProductSKU.stdCost === null
          ) {
            stdCost = 0
          } else {
            stdCost = item.ProductSKU.stdCost
          }

          if (item.SKURatio == undefined) {
            SKURatio = 1
          } else {
            SKURatio = item.SKURatio
          }

          totalCost = SKURatio * stdCost
          let checkCount = item.checkCount;
          let quantity = checkCount !== undefined ? checkCount : 0;
          let vatType = item.ProductSKU.vatType
          if (items.length > 0) {
            let i = items.findIndex(
              (x) => x.productPLU.objectId === item.objectId
            )
            if (i == -1) {
              items.push({
                cost: totalCost,
                discount: 0,
                orderIndex: index,
                productPLU: {
                  id: item.id,
                  objectId: item.objectId,
                  PLUCode: item.PLUCode,
                  name: item.name,
                  unit: item.unit,
                  SKURatio: SKURatio,
                },
                productPLUId: item.id,
                quantity: quantity,
                total: 0,
                productSKUObjectId: item.ProductSKU.objectId,
                productSKU: item.ProductSKU || {},
                remoteImagePath: item.remoteImagePath,
                indexColor: util.generateColor(item.indexColor),
                vatType: vatType
              })
            }
          } else {
            items.push({
              cost: totalCost,
              discount: 0,
              orderIndex: index,
              productPLU: {
                id: item.id,
                objectId: item.objectId,
                PLUCode: item.PLUCode,
                name: item.name,
                unit: item.unit,
                SKURatio: SKURatio,
              },
              productPLUId: item.id,
              quantity: quantity,
              total: 0,
              productSKUObjectId: item.ProductSKU.objectId,
              productSKU: item.ProductSKU || {},
              remoteImagePath: item.remoteImagePath,
              indexColor: util.generateColor(item.indexColor),
              vatType: vatType
            })
          }
        } else {
          let index = items.findIndex(
            (x) => x.productPLU.objectId === item.objectId
          )
          if (index != -1) {
            items.splice(index, 1)
          }
          
        }
      })
      this.items = items
      this.selectedItems = select
      this.AddProductModal = false
      this.AddCheckStockModal = false
      this.all = false
    },
    setAll() {
      let select = this.selectedItems
      let data = this.autoCompleteResult
      // this.selectedItems = []
      if (this.all) {
        data.forEach(function(item) {
          item.selected = true
          select.push(item)
        })
      } else {
        data.forEach(function(item) {
          item.selected = false
          let i = select.findIndex((x) => x.objectId === item.objectId)
          if (i != -1) {
            select.splice(i, 1)
          }
        })
      }
      this.selectedItems = select
      this.autoCompleteResult = data
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
<style scoped>
.multiselect__tags {
  min-height: 35px;
  padding: 0px 40px 0 8px;
}
.multiselect__placeholder {
  margin-top: 6px;
  margin-bottom: 7px;
}
.multiselect__single {
  margin-top: 6px;
  padding-left: 5px;
  margin-bottom: 8px;
}
/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-body{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../assets/styles/inventory.css" scoped></style>
